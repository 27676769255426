const banList = [
    "06055",
    "06056",
    "06057",
    "06058",
    "06059",
    "06444",
    "06445",
    "064460",
    "064461",
    "064462",
    "064463",
    "064464",
    "064465",
    "064466",
    "064467",
    "064468",
    "064469",
    "07453",
    "07454",
    "07455",
    "07456",
    "07457",
    "07482",
    "07483",
    "07484",
    "07485",
    "07486",
    "07510",
    "07511",
    "07512",
    "07513",
    "07514",
    "07515",
    "07518",
    "07519",
    "07521",
    "07522",
    "07523",
    "07524",
    "07525",
    "07527",
    "07528",
    "07529",
    "0753",
    "07540",
    "07541",
    "07542",
    "07544",
    "07545",
    "07550",
    "07551",
    "07552",
    "07553",
    "07554",
    "07557",
    "07558",
    "07559",
    "075635",
    "07565",
    "07566",
    "07567",
    "07568",
    "07569",
    "075780",
    "075781",
    "075782",
    "075783",
    "075784",
    "0758",
    "0773",
    "0774",
    "07807",
    "07808",
    "078090",
    "078091",
    "078092",
    "078093",
    "078094",
    "078095",
    "078096",
    "078097",
    "078098",
    "078099",
    "010000",
    "020000",
    "030000",
    "040000",
    "050000",
    "060000",
    "070000",
    "080000",
    "090000",
]
const verifNum = async (num) => {
    let occurence = "";
    banList.forEach((n) => {
        if(n === num.substr(0, n.length)){
            //ajout de l'occurence trouvé parmi la liste de numéro à bannir
            return occurence = n;
        }
    })
    if(occurence !== ""){
        //numéro non valide
        return {success: false}
    }
    if(num.length > 10){
        return {success: false}
    }
    //numéro valide
    return {success: true}
}


export default verifNum;