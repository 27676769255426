const sendForm = async(client, postes, link) => {

    try {

        const send = await fetch(`${process.env.REACT_APP_DOMAINE}email/sendform-landing`, {
            method: 'POST',
            headers: {
                "content-type" : "application/json"
            },
            body: JSON.stringify({
                client,
                postes,
                link
            })
        })
    
        return send.json()

    } catch(err) {
        throw err
    }

}

export default sendForm