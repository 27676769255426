import { Routes, Route, BrowserRouter } from "react-router-dom";

import FormPage3 from "./pages/FormPage3";

function App() {

  document.addEventListener('contextmenu', event => event.preventDefault());

  document.onkeydown = function(e) {
      if (e.ctrlKey && (e.keyCode === 85 || e.keyCode === 117 || e.keyCode === 115 || e.keyCode === 83)) {//Alt+c, Alt+v will also be disabled sadly.
          return false;
      }
      return true;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<FormPage3 />} />
            <Route path="/:link" element={<FormPage3 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
