import { useState } from 'react'
import { useCookies } from "react-cookie";
import Recaptcha from 'react-recaptcha';

import '../assets/scss/FormPage3.scss'

import logoColor from '../assets/img/logo.svg'
import entreprise1 from '../assets/img/entreprise1.png'
import entreprise2 from '../assets/img/entreprise2.png'
import entreprise3 from '../assets/img/entreprise3.png'
import entreprise4 from '../assets/img/entreprise4.png'
import check from '../assets/img/checked_white.png'

import sendForm from '../assets/js/Sendinbue';
import verifNum from '../assets/js/PrefixBlacklist';
import verifMail from '../assets/js/DomainBlacklist';
import { useParams } from 'react-router';

const FormPage3 = (props) => {

    const [cookies, setCookies] = useCookies({});
    const [send, setSend] = useState(false);
    const [error, setError] = useState("");
    const [isError, setIserror] = useState(false);
    const [newsletter, setNews] = useState(true);
    const date = new Date();
    const { link } = useParams();

    const sendFormulaire = async (e) => {
        e.preventDefault()

        const client = {
            nom: document.querySelector('#lname').value,
            prenom: document.querySelector('#fname').value,
            email: document.querySelector('#email').value,
            societe: document.querySelector('#societe').value,
            telephone: document.querySelector('#tel').value,
        }

        const postes = document.querySelector('#postes').value

        if (client.nom === '' || client.prenom === '' || client.email === '' || client.societe === '' || client.telephone === '' || postes === '') {
            setIserror(true)
            return setError('Tous les champs ne sont pas remplis !')
        }

        const re = /\S+@\S+\.\S+/;
        const regexTel = /(0|\\+33|0033)[1-9][0-9]{8}/;

        if (!re.test(client.email)) {
            setIserror(true)
            return setError('L\'adresse email n\'est pas correct !')
        }

        if (!regexTel.test(client.telephone)) {
            setIserror(true)
            return setError('Le numéro de téléphone inscrit n\'est pas valide.')
        }

        const vMail = await verifMail(client.email);
        const verif = await verifNum(client.telephone);

        if (vMail.success === true) {
            setIserror(false)
            if (verif.success === true) {
                const form = await sendForm(client, postes, link !== undefined ? link : false)

                if (form.success) {
                    date.setMinutes(date.getMinutes() + 60);
                    setCookies("send", true, { expires: date });
                    setSend(true)
                } else {
                    alert('Une erreur est survenue !')
                }
            }
            else {
                setIserror(true)
                setError("Le numéro de téléphone inscrit n'est pas valide.")
            }
        }
        else {
            setIserror(true)
            setError('L\'adresse email n\'est pas valide !')
        }
    }

    const isNumberKey = (evt) => {
        let charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
        }
    }

    return (
        <div className="form3">

            <div className="form3-container">

                <header>
                    <img src={logoColor} alt="logo-colortelecom" width="400px" height="auto" />
                </header>

                <main>
                    <h1>Essayez gratuitement pendant 30 jours</h1>
                    <div className="container">
                        <h2>Votre téléphonie d'entreprise:</h2>
                        <h2 style={{ fontSize: '40px' }}>19.90€ HT</h2>
                        <p>utilisateur / mois</p>
                        <div className="pictos">

                            <div>
                                <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                <span>Appels entrants et sortants en illimités</span>
                            </div>

                            <div>
                                <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                <span>Application Desktop et Mobile</span>

                            </div>

                            <div>
                                <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                <span>Intégration CRM en 1 minute</span>
                            </div>


                            {/* <div>
                                <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                <span>Statistiques avancées</span>
                            </div> */}


                            <div>
                                <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                <span>Garder votre numéro historique</span>
                            </div>
                            
                            
                            
                            {/* <div>
                                <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                <span> Moderniser votre téléphonie</span>
                            </div> */}

                            

                            
                            <div>
                                <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                <span>Personnaliser votre standard en ligne</span>
                            </div>

                           

                            {/* <div>
                                <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                <span>installation et utilisation simple</span>
                            </div>

                            <div>
                                <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                <span>Activez votre Numéro et Standard Virtuel instantanément</span>
                            </div>

                            <div>
                                <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                <span>Adoptez les outils importants pour le télétravail</span>
                            </div> */}


                        </div>
                    </div>
                    <div className="form">
                        {
                            !send && !cookies.send ?
                                // <form onSubmit={(e) => sendFormulaire(e) | setError(false)}>
                                <form onSubmit={(e) => sendFormulaire(e)}>
                                    <div className="flex">
                                        <div>
                                            <label>Prénom</label>
                                            <input required type="text" id='fname' placeholder="ex: Justine" name="fname" />
                                        </div>
                                        <div>
                                            <label>Nom</label>
                                            <input required type="text" id='lname' placeholder="ex: Petit" name="lname" />
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div>
                                            <label>Adresse email</label>
                                            <input required id='email' type="email" placeholder="ex: Justinepetit@gmail.com" name="email" />
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div>
                                            <label>Société</label>
                                            <input required type="text" id='societe' placeholder="ex: Justine consulting" name="business" />
                                        </div>
                                        <div>
                                            <label>Téléphone</label>
                                            <input required type="tel" onKeyPress={(event) => { return isNumberKey(event) }} id='tel' placeholder="ex: 06 58 99 25 22" maxLength="10" name="phone" />
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div>
                                            <label>Nombre de poste</label>
                                            <select required id='postes'>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        isError ? <p className="error">{error}</p> : ""
                                    }
                                    <button type="submit">Démarrer</button>

                                    
                                    <div style={{display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
                                    <Recaptcha
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                        type="audio"
                                    />
                                    </div>
                                </form>
                                :
                                <>
                                    <h2>Merci pour votre confiance</h2>
                                    <div className="text">
                                        <p>Notre équipe commerciale vous contactera dans les plus brefs délais pour mettre en place votre période d'essai gratuite sans engagement durant 7 jours.</p>
                                        <br />
                                        <p>Si vous souhaitez, vous pouvez nous contacter directement au 01 87 200 200.</p>
                                    </div>
                                    <div className="btn">
                                        <span>Complété</span>
                                        <img src={check} alt="check-colortelecom" width="25px" height="25px" />
                                    </div>
                                </>
                        }
                    </div>
                </main>

            </div>

            <footer className={`${send ? 'footer-send' : 'footer-nosend'}`}>
                <h2>Plus de 5 000 clients nous font confiance</h2>
                <div>
                    <img src={entreprise1} alt="entreprise-colortelecom" width="80px" height="auto" />
                    <img src={entreprise2} alt="entreprise-colortelecom" width="80px" height="auto" />
                    <img src={entreprise3} alt="entreprise-colortelecom" width="80px" height="auto" />
                    <img src={entreprise4} alt="entreprise-colortelecom" width="80px" height="auto" />
                </div>
                
                
            </footer>
            <div className='checkbox-container'>
                                        <input
                                        type="checkbox" onClick={() => setNews(!newsletter)} checked={newsletter} />
                                    <label style={{marginLeft: "5px"}}>
                                    je souhaite recevoir des offres promotionnelles
                                    </label>
                                    </div>

        </div>
    )
}

export default FormPage3