const blackListMail = [
    "mail.com",
    "mailinator.com",
    "yopmail.com",
    "jetable.org",
    "trashmail.com",
    "getnada.com",
    "test.com",
    "test.fr",
    "mail.ru",
    "mail.net",
]

const verifMail = async (email) => {
    let domaine = "";
    blackListMail.forEach((x)=>{
        if(email.indexOf(`@${x}`) !== -1){
            return domaine = x
        }
    })
    if(domaine === ""){
        // console.log("good mail")
        return {success: true}
    }
    // console.log(email)
    return {success: false}
}

export default verifMail;